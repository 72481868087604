import $ from 'jquery';
import exportBtns from './util/export-buttons';
import services from '@/services/services.service.js';
import { statusColors, fastIcons, serverUrl } from '@/global/variables';

// Service Options
export const serviceOptions = async () => {
	return [
		{ value: null, text: 'Service' },
		...(await services.fetch('?unactive=1')).map((s) => ({
			text: s.name,
			value: s.name
		}))
	];
};
// Setup Table
export const setupTable = ({ url = '/api/orders', userColumn = false, otherConfig }) => {
	const columns = [
		{ data: 'id', name: "orders.id" },
		{ data: 'status' },
		{ data: 'name', name: "services.name" },
		{ data: 'imei' },
		{ data: 'result' },
		{ data: 'created_at', name: "services.created_at" },
		{ data: 'credit' },
		{ data: 'note' },
		{ data: 'services.category' }
	];
	userColumn && columns.push({ data: 'username', name: "users.username" });
	return $('#vue-datatable').DataTable({
		processing: true,
		serverSide: true,
		responsive: true,
		scrollX: true,
		ajax: {
			url: serverUrl(url),
			beforeSend: function(request) {
				request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
			}
		},
		buttons: [ ...exportBtns({ 1: 'Status', 2: 'Service' }), 'columnsToggle' ],
		dom: 'Bfrtip',
		pageLength: 50,
		columns: [ ...columns ],
		columnDefs: [
			{
				targets: 0
			},
			{
				targets: 1,
				orderable: false
			},
			{
				targets: 2,
				orderable: false
			},
			{
				targets: 4,
				orderable: false
			},
			{
				render: function(data, type, row) {
					const classesBadge = [ 'badge', `badge-${statusColors[data]}`, 'badge-pill', 'resp-status' ].join(
						' '
					);
					// const content = fastIcons[data] ? `<div class="draw">${fastIcons[data]}</div>` : data;
					return `<span class='${classesBadge}'>${data}</span>`;
				},
				targets: 1
			},
			{
				render: function(data, type, row) {
					return data || 'No Note';
				},
				targets: 7
			},
			{
				visible: false,
				targets: 8
			}
		],
		order: [ [ 0, 'desc' ] ],
		...otherConfig
	});
};
